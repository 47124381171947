import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import EditIcon from '../../components/icon/EditIcon';
import PlaqueOrder from '../../fragments/PlaqueOrder';
import AddressAutocomplete, { createAddressComponentFields } from '../../components/form/AddressAutocomplete';
import Checkbox from '../../components/form/Checkbox';

const clientPostalComponentFields = createAddressComponentFields('Client.PostalAddress');
const clientResidentialComponentFields = createAddressComponentFields('Client.ResidentialAddress');

class ContactDetailsTab extends Component {
    state = {
        readonlyClientDetails: true
    };

    render() {
        return (
            <ColumnLayout>
                {this.renderLeftColumn()}
                {this.renderRightColumn()}
            </ColumnLayout>
        );
    }

    renderLeftColumn() {
        return <Fragment>{this.renderApplicant()}</Fragment>;
    }

    renderRightColumn() {
        return <Fragment />;
    }

    renderApplicant() {
        const { form } = this.props;
        const { readonlyClientDetails } = this.state;

        return (
            <Grid container bucket>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <h4>Applicant Details</h4>
                        <IconButton
                            className="icon"
                            onClick={() => this.setState({ readonlyClientDetails: !readonlyClientDetails })}
                        >
                            <EditIcon />
                        </IconButton>
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First name"
                        form={form}
                        name="Client.FirstName"
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Surname" form={form} name="Client.Surname" readOnly={readonlyClientDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Relationship to deceased"
                        form={form}
                        name="Client.RelationshipToDeceased"
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Phone" form={form} name="Client.PrimaryNumber" readOnly={readonlyClientDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Mobile"
                        form={form}
                        name="Client.SecondaryNumber"
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Email" form={form} name="Client.Email" readOnly={readonlyClientDetails} />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Residential Address"
                        placeholder="Search for residential address..."
                        form={form}
                        name="Client.ResidentialAddress"
                        componentFields={clientResidentialComponentFields}
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                {(!readonlyClientDetails || !!form.getField('Client.PostalAddressSameAsResidentialAddress')) && (
                    <Grid item xs={12}>
                        <Checkbox
                            form={form}
                            name="Client.PostalAddressSameAsResidentialAddress"
                            label="Postal Address same as Residential Address"
                            readOnly={readonlyClientDetails}
                        />
                    </Grid>
                )}

                {!form.getField('Client.PostalAddressSameAsResidentialAddress') && (
                    <Grid item xs={12}>
                        <AddressAutocomplete
                            allowCustomAddress
                            label="Postal Address"
                            placeholder="Search for postal address..."
                            form={form}
                            name="Client.PostalAddress"
                            componentFields={clientPostalComponentFields}
                            readOnly={readonlyClientDetails}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }
}

export default {
    id: 'ContactDetails',
    label: 'Contact Details',
    component: withStyles({})(ContactDetailsTab),
    fragment: PlaqueOrder,
    validation: {
        required: ['Client.FirstName']
    }
};
