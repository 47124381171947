import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { joinDefined, prettyNumber, stringIsNullOrEmpty } from '../../utils/strings';
import Table, { Cell, HeaderRow, Row } from './Table';
import Inline, { inlineAlignment } from '../layout/Inline';
import {
    getFinalInsertionDate,
    niceTimeFromString,
    shortDateFromString,
    shortDateTimeFromString
} from '../../utils/date';
import { getBrandHomeByKey } from '../../utils/brands';
import AlertBar from '../form/AlertBar';
import Button from '../form/Button';
import { isRelatedObjectDefined } from '../../utils/bookable';

export default withStyles({})(({ variant, runsheetType, runsheetStatus, cremation, children }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `runsheet--${variant}`;
    const spacer = '(not set)';
    const deceased = cremation.Deceased || false;
    const deceasedName = !!deceased && joinDefined([deceased.FirstName, deceased.MiddleName, deceased.Surname], ' ');
    const recordID = cremation.ID;
    const legacyKey = cremation.LegacyKey;
    const funeralBrand = getBrandHomeByKey(legacyKey).label; // todo
    const assignedStaff = joinDefined(
        [cremation.CremationOperator.FirstName, cremation.CremationOperator.Surname],
        ' '
    );
    const witnessInsertion = 'Required' === cremation.WitnessOfInsertionRequired;
    const funeralService = cremation.FuneralService || cremation.Funeral.PlaceOfService.Type;
    const cremationDateTime = shortDateTimeFromString(joinDefined([
        (cremation.DeliveryDate || cremation.EstimatedDeliveryDate || cremation.CremationDate),
        (cremation.DeliveryTime || cremation.EstimatedDeliveryTime || cremation.CremationTime)
    ], ' '));
    const deceasedWeight = cremation.DeceasedWeight;
    const estimatedDeceasedWeight = cremation.EstimatedDeceasedWeight;
    const sameDay = !!cremation.SameDayCremation;
    const reflectionRoomAttendees = joinDefined(
        [
            !!cremation.ReflectionRoom.Applicant ? 'Applicant' : null,
            !!cremation.ReflectionRoom.AuthorisedContact ? 'Authorised Contact' : null,
            cremation.ReflectionRoom.AttendeeNames
        ],
        ', '
    );

    const renderReflectionRoomDetails = () => {
        return (
            !!parseInt(cremation.ReflectionRoom.ID) && (
                <Row pad>
                    <Cell colSpan={2}>
                        <h5>Reflection Room Booking:</h5>
                        <p>
                            <span className="info">When:&nbsp;</span>
                            {shortDateFromString(cremation.ReflectionRoom.Start)}
                            <span> from </span>
                            {(niceTimeFromString(cremation.ReflectionRoom.Start) || '(not set)') +
                                ' to ' +
                                (niceTimeFromString(cremation.ReflectionRoom.End) || '(not set)')}
                        </p>
                        {!!reflectionRoomAttendees && (
                            <p>
                                <span className="info">Attendees:&nbsp;</span>
                                {reflectionRoomAttendees}
                            </p>
                        )}
                    </Cell>
                </Row>
            )
        );
    };

    return (
        <div className={`runsheet ${cssVariant || ''}`}>
            <Table thead={
                <HeaderRow pad>
                    <Cell colSpan={2}>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div>
                                <span className="runsheet-header">
                                    {runsheetStatus} | {runsheetType}:&nbsp;
                                    {deceasedName}
                                </span>
                            </div>
                            <p className="header-record">
                                <Button variant="link-white" href={'/cremations/' + recordID}>
                                    {legacyKey}
                                </Button>
                                {!!funeralBrand && ' | ' + funeralBrand}
                            </p>
                        </Inline>
                    </Cell>
                </HeaderRow>
            }>
                <Row pad>
                    <Cell>
                        <h5>Delivery</h5>
                        <p>
                            <span className="info">{!!cremation.DeliveryDate ? 'Arrived' : 'Expected'}:&nbsp;</span>
                            {cremationDateTime}
                        </p>

                        <p>
                            <span className="info">Delivery by:&nbsp;</span>
                            {cremation.DeliveredBy || spacer}
                        </p>

                        {(!!cremation.DeliveryDate || isRelatedObjectDefined(cremation.DeliveryReceivedBy)) && (
                            <p>
                                <span className="info">Received by:&nbsp;</span>
                                {joinDefined([
                                    cremation.DeliveryReceivedBy.FirstName,
                                    cremation.DeliveryReceivedBy.Surname
                                ], ' ') || spacer}
                            </p>
                        )}

                        {!!deceasedWeight
                            ? (
                                <p>
                                    <span className="info">Weight:&nbsp;</span>
                                    {prettyNumber(deceasedWeight)}&nbsp;kg
                                </p>
                            ) : (!!estimatedDeceasedWeight ? (
                                <p>
                                    <span className="info">(Est) Weight:&nbsp;</span>
                                    {prettyNumber(estimatedDeceasedWeight)}&nbsp;kg
                                </p>
                            ) : (
                                <p>
                                    <span className="info">Weight:&nbsp;</span>
                                    {spacer}
                                </p>
                            ))
                        }

                        {cremation.IsDeliveryOnly ? (
                            <p>
                                <span className="info">Funeral service:&nbsp;</span>
                                Delivery only
                            </p>
                        ) : (!!funeralService &&
                            <p>
                                <span className="info">Funeral service:&nbsp;</span>
                                {'Service' === funeralService ? ' Full Service' : funeralService}
                            </p>
                        )}

                        {cremation.DispatchDates.map((dispatchDate, i) => {
                            return (
                                <Fragment key={dispatchDate.ID}>
                                    <h5>
                                        Dispatch #{i + 1}
                                    </h5>
                                    {dispatchDate.DispatchComplete ? (<>
                                            <p>
                                                <span className="info">Completed.</span>
                                            </p>
                                            {dispatchDate.Ashes && (
                                                <p>
                                                    <span className="info">Ashes:&nbsp;</span>
                                                    {dispatchDate.Ashes}
                                                </p>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <p>
                                                <span className="info">Dispatch date:&nbsp;</span>
                                                {shortDateFromString(dispatchDate.Date)}
                                                {!!dispatchDate.Time && ', ' + niceTimeFromString(dispatchDate.Time)}
                                            </p>
                                            {dispatchDate.ContainmentStyle && (
                                                <p>
                                                    <span className="info">Containment:&nbsp;</span>
                                                    {dispatchDate.ContainmentStyle}
                                                </p>
                                            )}
                                            {dispatchDate.Ashes && (
                                                <p>
                                                    <span className="info">Ashes:&nbsp;</span>
                                                    {dispatchDate.Ashes}
                                                </p>
                                            )}
                                            <p>
                                                <span
                                                    className="info">{dispatchDate.DispatchMethod || 'Collected by'}:&nbsp;</span>
                                                {dispatchDate.AshesCollectedBy || spacer}
                                            </p>
                                            <p>
                                                <span className="info">Assigned staff:&nbsp;</span>
                                                {joinDefined(dispatchDate.AssignedStaff.map(obj => joinDefined([
                                                    obj.FirstName,
                                                    obj.Surname
                                                ], ' ')), ', ') || spacer}
                                            </p>
                                            {dispatchDate.Comment && (
                                                <p>
                                                    <span className="info">Comments:&nbsp;</span>
                                                    {dispatchDate.Comment}
                                                </p>
                                            )}
                                        </>
                                    )}
                                </Fragment>
                            );
                        })}
                    </Cell>

                    <Cell>
                        <h5>Action</h5>
                        <p>
                            <span className="info">Final Insertion:&nbsp;</span>
                            {shortDateFromString(sameDay
                                ? (cremation.DeliveryDate || cremation.EstimatedDeliveryDate || cremation.CremationDate)
                                : getFinalInsertionDate(cremation.DeliveryDate || cremation.EstimatedDeliveryDate || cremation.CremationDate)
                            )}
                        </p>

                        <p>
                            <span className="info">Operator:&nbsp;</span>
                            {assignedStaff || spacer}
                        </p>

                        <p>
                            <span className="info">Scheduled date:&nbsp;</span>
                            {shortDateFromString(cremation.EstimatedInsertionDate) || spacer}
                        </p>

                        {!!sameDay && (
                            <AlertBar variant="attention">
                                SAME-DAY CREMATION
                            </AlertBar>
                        )}
                        {!!witnessInsertion && (
                            <AlertBar variant="warning">
                                WITNESS OF INSERTION
                            </AlertBar>
                        )}
                        {!cremation.ReadyForCremation ? (
                            <AlertBar variant="error">
                                This record has not been marked as ready for cremation.
                            </AlertBar>
                        ) : (!!cremation.HasMissingCertificates && (
                            <AlertBar variant="error">
                                This record has been marked as ready for cremation, but has incomplete documentation.
                            </AlertBar>
                        ))}

                        {!!children.length && (
                            <Fragment>
                                <h5>Special Instructions:&nbsp;</h5>
                                {children}
                            </Fragment>
                        )}
                    </Cell>
                </Row>
                {renderReflectionRoomDetails()}
            </Table>

        </div>
    );
});
