import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import Button from '../../components/form/Button';
import RadioGroup from '../../components/form/RadioGroup';
import AshLocationHistoryModal from './Modals/AshLocationHistoryModal';
import Select from '../../components/form/Select';
import { CollectedByOptions, DispatchMethodOptions } from './PlaqueConstants';
import ContactCard from '../../components/contact/ContactCard';
import PlaqueOrder from '../../fragments/PlaqueOrder';
import { numberToWords } from '../../utils/strings';
import DeleteIcon from '../../components/icon/DeleteIcon';
import AlertModal from '../../components/modal/AlertModal';
import EditIcon from '../../components/icon/EditIcon';
import ContactLookup from '../../components/contact/ContactLookup';
import PlaqueCombinationLocation from '../../fragments/PlaqueCombinationLocation';
import Typography from '../../components/form/Typography';
import { MaritalStatusOptions } from '../../components/ComponentConstants';
import AddressAutocomplete, { createAddressComponentFields } from '../../components/form/AddressAutocomplete';

const deceasedAddressComponentFields = createAddressComponentFields('Deceased.ResidentialAddress');

class RecordDetailsTab extends Component {
    state = {
        readonlyDeceasedDetails: true,
        showAshLocationHistoryModal: false,
        showDeleteAshOrderModal: false,
        editAshLocationHistoryIndex: null,
        showDeleteAshLocationAlertModal: false
    };

    render() {
        const {
            showAshLocationHistoryModal,
            showDeleteAshOrderModal,
            editAshLocationHistoryIndex,
            showDeleteAshLocationAlertModal
        } = this.state;

        const { form, index } = this.props;

        let editAshLocationHistory = {
            ID: null,
            Location: '',
            LocationDetail: '',
            Notes: '',
            Changes: []
        };

        if (editAshLocationHistoryIndex !== null && editAshLocationHistoryIndex !== undefined) {
            editAshLocationHistory = form.getState('AshLocations')[editAshLocationHistoryIndex];
        }

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <AlertModal
                    variant="warning"
                    open={showDeleteAshOrderModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteAshOrder()}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteAshOrderModal()}
                    onClose={() => this.handleCloseDeleteAshOrderModal()}
                >
                    <p>You're about to delete this ash order.</p>
                    <p>Are you sure?</p>
                </AlertModal>

                <AshLocationHistoryModal
                    open={showAshLocationHistoryModal}
                    onClose={() => this.handleCloseAshLocationHistoryModal()}
                    onCancel={() => this.handleCloseAshLocationHistoryModal()}
                    onSubmit={ashLocationHistory => this.handleSaveAshLocationHistoryModal(ashLocationHistory)}
                    ashLocationHistory={editAshLocationHistory}
                    name={'editAshLocationHistory'}
                />

                <AlertModal
                    variant="warning"
                    open={showDeleteAshLocationAlertModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteAshLocationHistory(index)}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteAshLocationAlertModal()}
                    onClose={() => this.handleCloseDeleteAshLocationAlertModal()}
                >
                    <p>You're about to delete this ash location history.</p>
                    <p>Are you sure?</p>
                </AlertModal>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderDeceasedDetails()}
                {this.renderOrderDetails()}
            </Fragment>
        );
    }

    renderDeceasedDetails() {
        const { form } = this.props;
        const { readonlyDeceasedDetails } = this.state;

        return (
            <Grid container bucket>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <h4>Deceased Details</h4>
                        <IconButton
                            className="icon"
                            onClick={() => this.setState({ readonlyDeceasedDetails: !readonlyDeceasedDetails })}
                        >
                            <EditIcon />
                        </IconButton>
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First name"
                        form={form}
                        name="Deceased.FirstName"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Middle name(s)"
                        form={form}
                        name="Deceased.MiddleName"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Surname" form={form} name="Deceased.Surname" readOnly={readonlyDeceasedDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Date of Birth"
                        type="date"
                        form={form}
                        name="Deceased.DateOfBirth"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Date of Death"
                        type="date"
                        form={form}
                        name="Deceased.DateOfDeath1"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select
                        label="Marital Status"
                        options={MaritalStatusOptions}
                        form={form}
                        name="Deceased.MaritalStatus"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Residential Address"
                        placeholder="Search for residential address..."
                        form={form}
                        name="Deceased.ResidentialAddress"
                        componentFields={deceasedAddressComponentFields}
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>
            </Grid>
        );
    }

    renderOrderDetails() {
        const { form } = this.props;
        const contact = form.getField('PlaqueLocation');

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Plaque Installation</h4>
                </Grid>

                {(!contact && (
                    <Grid item>
                        <ContactLookup
                            name="PlaqueLocation.ID"
                            onSelect={(_, addressBook) =>
                                this.handleSelectBusinessContact('PlaqueLocation', addressBook)
                            }
                            onAddNewContact={addressBook =>
                                this.handleSelectBusinessContact('PlaqueLocation', addressBook)
                            }
                            readAllQuery={readAllAddressBooksQuery}
                        />
                    </Grid>
                )) || (
                    <Fragment>
                        <Grid item>
                            <div style={{ float: 'right', marginTop: -24 }}>
                                <Button variant="link-orange" onClick={() => form.setField({ PlaqueLocation: null })}>
                                    Change Location
                                </Button>
                            </div>

                            <ContactCard
                                variant="fullwidth" //Do no remove
                                contact={contact}
                            />
                        </Grid>
                    </Fragment>
                )}

                <Grid item xs={12} sm={6}>
                    <TextField
                        type="date"
                        label="Plaque Installation Date"
                        form={form}
                        name="InstallationDate"
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        type="time"
                        label="Plaque Installation Time"
                        form={form}
                        name="InstallationTime"
                        required
                    />
                </Grid>

                <Grid item>
                    <Inline center>
                        <div>
                            <h5>Family in attendance?</h5>
                        </div>
                        <RadioGroup
                            options={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' }
                            ]}
                            value={form.getField('FamilyAttendance') ? 'yes' : 'no'}
                            onChange={e => form.setState({ FamilyAttendance: e.target.value === 'yes' })}
                        />
                    </Inline>
                </Grid>

                {form.getField('FamilyAttendance') && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="date"
                                label="Family Attendance Date"
                                form={form}
                                name="FamilyAttendanceDate"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Family Attendance Time" form={form} name="FamilyAttendanceTime" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Family Attendance Notes" form={form} name="FamilyContact" />
                        </Grid>
                    </Fragment>
                )}

                <Grid item>
                    <Inline center>
                        <div>
                            <h5>Placement by council?</h5>
                        </div>
                        <RadioGroup
                            options={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' }
                            ]}
                            value={form.getField('PlacementByCouncil') ? 'yes' : 'no'}
                            onChange={e => form.setState({ PlacementByCouncil: e.target.value === 'yes' })}
                        />
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    handleSelectBusinessContact(propertyName, addressBook) {
        const { form } = this.props;
        delete addressBook['__typename'];

        if (!!addressBook) {
            form.setState({ [propertyName]: addressBook });
        }
    }

    renderRightColumn() {
        return (
            <Fragment>
                {this.renderAshLocation()}
                {this.renderOrderAshes()}
            </Fragment>
        );
    }

    renderOrderAshes() {
        const { form } = this.props;
        const orderAshes = form.getState('Ashes') || [];

        return (
            <Grid container bucket>
                <Grid item xs={12}>
                    <h4>Ashes</h4>
                </Grid>

                {orderAshes.map((ashOrder, index) => this.renderAshOrder(ashOrder, index))}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleAddAshOrder()}>
                        + Add Ash
                    </Button>
                </Grid>
            </Grid>
        );
    }

    renderAshOrder(ashOrder, index) {
        const { form } = this.props;
        return (
            <Fragment key={'ashorder' + index}>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <h5>Ash Order {numberToWords(index + 1)}</h5>
                        <IconButton
                            className="icon"
                            title={'Delete'}
                            onClick={() => this.handleShowDeleteAshOrderModal()}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Ashes" form={form} name={`Ashes[${index}].Ashes`} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Vase" form={form} name={`Ashes[${index}].ContainmentStyle`} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select
                        label="Dispatch Method"
                        options={DispatchMethodOptions}
                        form={form}
                        name={`Ashes[${index}].DispatchMethod`}
                    />
                </Grid>

                {form.getField(`Ashes[${index}].DispatchMethod`) === 'Collected by' && (
                    <Grid item xs={12} sm={6}>
                        <Select
                            label="Ashes collected by"
                            options={CollectedByOptions}
                            form={form}
                            name={`Ashes[${index}].AshesCollectedBy`}
                        />
                    </Grid>
                )}

                <Grid item>
                    <TextField
                        label="Comment"
                        name={`Ashes[${index}].Comment`}
                        form={form}
                        multiline
                        button={{
                            variant: 'secondary',
                            children: '+ Add Comments'
                        }}
                    />
                </Grid>
            </Fragment>
        );
    }

    renderAshLocation() {
        const { form } = this.props;
        const ashLocations = form.getState('AshLocations') || [];
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Ash Location</h4>
                </Grid>

                {ashLocations.length > 0 &&
                    ashLocations.map((ashLocation, index) => (
                        <Grid item key={ashLocation.ID}>
                            <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                                <div>
                                    <Typography component="p">
                                        {ashLocation.Location}
                                        {ashLocation.Ashes && ` (${ashLocation.Ashes})`}
                                    </Typography>
                                    <Typography component="p"><small>{ashLocation.LocationDetail}</small></Typography>
                                </div>
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowAshLocationHistoryModal(index)}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowDeleteAshLocationAlertModal()}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {/*<IconButton className="icon" onClick={() => this.handleDeleteAshLocationHistory(index)}><DeleteIcon /></IconButton>*/}
                            </Inline>
                        </Grid>
                    ))}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleShowAshLocationHistoryModal()}>
                        Log Ash Location...
                    </Button>
                </Grid>
            </Grid>
        );
    }

    handleAddAshOrder() {
        const { form } = this.props;
        const Ashes = form.getState('Ashes') || [];
        Ashes.push({});
        form.setState({ Ashes });
    }

    handleDeleteAshOrder(index) {
        const { form } = this.props;
        const Ashes = form.getState('Ashes') || [];

        if (index !== -1) {
            Ashes.splice(index, 1);
            form.setState({ Ashes });
        }

        this.handleCloseDeleteAshOrderModal();
    }

    handleDeleteAshLocationHistory(index) {
        const { form } = this.props;
        const AshLocations = form.getState('AshLocations') || [];

        if (index !== -1) {
            AshLocations.splice(index, 1);
            form.setState({ AshLocations });
        }
    }

    handleShowDeleteAshOrderModal() {
        this.setState({ showDeleteAshOrderModal: true });
    }

    handleCloseDeleteAshOrderModal() {
        this.setState({ showDeleteAshOrderModal: false });
    }

    handleShowAshLocationHistoryModal(index) {
        this.setState({ editAshLocationHistoryIndex: index });
        this.setState({ showAshLocationHistoryModal: true });
    }

    handleCloseAshLocationHistoryModal() {
        this.setState({ showAshLocationHistoryModal: false });
        this.setState({ editAshLocationHistoryIndex: null });
    }

    handleSaveAshLocationHistoryModal(ashLocation) {
        const { form } = this.props;
        const { editAshLocationHistoryIndex } = this.state;
        const AshLocations = form.getState('AshLocations') || [];

        if (
            editAshLocationHistoryIndex !== null &&
            editAshLocationHistoryIndex !== undefined &&
            AshLocations[editAshLocationHistoryIndex]
        ) {
            const existingAshLocationHistory = AshLocations[editAshLocationHistoryIndex];
            Object.assign(existingAshLocationHistory, ashLocation);
        } else {
            AshLocations.push(ashLocation);
        }
        this.setState({ showAshLocationHistoryModal: false });
        this.setState({ editAshLocationHistoryIndex: null });

        form.setState({ AshLocations });
        form.save();
    }

    handleShowDeleteAshLocationAlertModal() {
        this.setState({ showDeleteAshLocationAlertModal: true });
    }

    handleCloseDeleteAshLocationAlertModal() {
        this.setState({ showDeleteAshLocationAlertModal: false });
    }
}

const readAllAddressBooksQuery = gql`
    ${PlaqueCombinationLocation}

    query ReadCremationManagerAddressBooks($contains: String) {
        readCremationManagerAddressBooks(contains: $contains, limit: 5, offset: 0) {
            edges {
                node {
                    ...PlaqueCombinationLocation
                }
            }
        }
    }
`;
export default {
    id: 'PlaqueOrderDetails',
    label: 'Order Details',
    component: withStyles({})(RecordDetailsTab),
    fragment: PlaqueOrder,
    validation: {
        required: ['Deceased.FirstName']
    },
    formatSaveData: (saveData, state) => {
        const location = state.PlaqueLocation;
        if (location && !!location.ID) {
            saveData.PlaqueLocationID = location.ID;
            delete saveData.PlaqueLocation;
        } else if (saveData.PlaqueLocation === null) {
            saveData.PlaqueLocationID = null;
            delete saveData.PlaqueLocation;
        }

        const combination = state.PlaqueCombination;
        if (combination && !combination.ID) {
            saveData.PlaqueCombinationID = 0;
            delete saveData.PlaqueCombination;
        }
    }
};
