import moment from 'moment';
import { pad } from './strings';
import { isNullOrUndefined } from './objects';

/**
 * converts a date to a string, formatted as dd-MM-yyyy
 *
 * @param date date to format
 */
export function dateToString(date) {
    /*
    let str = date.getFullYear() + '-' + pad(date.getMonth() + 1 + '', 2) + '-' + pad(date.getDate() + '', 2);
    */
    if (!!date && moment(date).isValid()) return moment(date).format('YYYY-MM-DD');
    return null;
}

/**
 * converts a datetime to a string, formatted as yyyy-MM-dd HH:mm:ss
 *
 * @param datetime datetime to format
 */
export const dateTimeToString = datetime => {
    if (!isNullOrUndefined(datetime) && moment(datetime).isValid())
        return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
    return null;
};

export function dateToTimeString(date) {
    if (!!date && moment(date).isValid()) return moment(date).format('HH:mm:ss');
    return null;
}

/**
 * converts a string to a date, expected format is yyyy-MM-dd HH:mm:ss
 *
 * @param str string to turn into a date
 */
export const stringToDate = str => {
    if (!isNullOrUndefined(str) && moment(str).isValid()) return moment(str).toDate();
    return null;
};

/**
 * gets the year delta between two dates
 * NOTE: the first date must be greater than the second, otherwise zero is returned
 *
 * @param date1 first date
 * @param date2 second date
 */
export const yearDelta = (date1, date2) => {
    return date1 > date2 ? 0 : Math.floor((date2 - date1) / (1000 * 60 * 60 * 24 * 365.25));
};

/**
 * gets the total days from the given date until today
 * used primarily to work out how old a record is
 * @param date old date
 */
export const dayDelta = (date1, date2) => {
    const fixedDate1 = typeof date1 === 'string' ? stringToDate(date1) : date1;

    const fixedDate2 = typeof date2 === 'string' ? stringToDate(date2) : date2;

    const dayDelta = (fixedDate2 - fixedDate1) / 1000 / 3600 / 24;
    return dayDelta;
};

/**
 * day of week. each day's int value corresponds to javascript's internal (new Date()).getDay() value
 */
const Days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

/**
 * day of week. each day's int value corresponds to javascript's internal (new Date()).getDay() value
 */
const Months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];

/**
 * finds the date of a given day of week. used to find the first day of the current week
 * @param {*} dayOfWeek DayOfWeek.[Sunday to Saturday] or int[0 to 6]
 * @param {*} date Javascript Date()
 */
const getFirstOccurrenceAtOrBefore = (dayOfWeek = 0, date = new Date()) => {
    if (dayOfWeek < 0) throw new Error('DayOfWeek cannot be less than zero (Sunday = 0)');

    if (dayOfWeek > 6) throw new Error('DayOfWeek cannot be greater than 6 (Saturday = 6)');

    let currentDate = date;
    while (currentDate.getDay() !== dayOfWeek) currentDate.setDate(currentDate.getDate() - 1);

    return currentDate;
};

/**
 * gets the sunday-commencing start of week date
 */
export const getStartOfWeek = () => {
    return getFirstOccurrenceAtOrBefore(0, new Date());
};

/**
 * adjusts a time string
 * @param {*} timeString
 * @param {*} minutes
 */
export const adjustTimeString = (timeString, minutes) => {
    if (!timeString || typeof timeString !== 'string') return '00:00';

    const parts = timeString.split(':');
    const date = new Date(2000, 1, 1, parseInt(parts[0], 10), parseInt(parts[1], 10), 0, 0);
    const adjustedDate = new Date(date.getTime() + minutes * 60000);

    return `${pad(adjustedDate.getHours(), 2)}:${pad(adjustedDate.getMinutes(), 2)}`;
};

/***
 * takes a date like 2005-09-23 and turns it into Thursday 23 September, 2005
 */
export const niceDateFromString = dateString => {
    if (isNullOrUndefined(dateString) || dateString.length < 1) return null;

    const date = stringToDate(dateString);

    return date && `${Days[date.getDay()]}, ${date.getDate()} ${Months[date.getMonth()]} ${date.getFullYear()}`;
};

export const shortDateFromString = dateString => {
    if (isNullOrUndefined(dateString) || dateString.length < 1) return null;

    const date = stringToDate(dateString);
    if (!moment(date).isValid()) return null;

    return `${moment(date).format('ddd DD/MM/Y')}`;
};

export const shortDateTimeFromString = dateString => {
    if (isNullOrUndefined(dateString) || dateString.length < 1) return null;

    const date = stringToDate(dateString);
    if (!moment(date).isValid()) return null;

    return `${moment(date).format('ddd DD/MM/Y, h:mmA')}`;
};

/***
 * takes a date like 2005-09-23 9:20 AM and turns it int Thursday 23 September, 2005 at 9.20am
 */
export const niceDateTimeFromString = dateString => {
    if (isNullOrUndefined(dateString) || dateString.length < 1) return null;

    const date = stringToDate(dateString);
    const hasTime = dateString.replace(/^\d\d\d\d-\d\d-\d\d\s?/, '');
    const hasDate = dateString.replace(/\s?\d?\d:\d\d(:\d\d|\s[AP]M)$/, '');

    if (date) {
        if (hasDate && hasTime) {
            return `${niceDateFromString(dateString)} at ${niceTimeFromString(dateString)}`;
        } else if (hasDate) {
            return `${niceDateFromString(dateString)}`;
        } else if (hasTime) {
            return `${niceTimeFromString(dateString)}`;
        }
    }
    return null;
};

/***
 * takes a time like 9:20:00 and turns it into 9.20 am
 */
export const niceTimeFromString = dateString => {
    if (isNullOrUndefined(dateString) || dateString.length < 1) return null;

    let date = stringToDate(dateString);
    if (!moment(date).isValid()) {
        date = stringToDate('2001-01-01 ' + dateString);
        if (!moment(date).isValid()) {
            return null;
        }
    }

    const hour = date.getHours() - (date.getHours() >= 12 ? 12 : 0);
    const period = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${hour === 0 ? 12 : hour}:${pad(date.getMinutes(), 2)} ${period}`;
};

export function getFinalInsertionDate(fromDate) {
    const newDate = moment(fromDate);
    let offset = 48; // skip weekends
    if (newDate.weekday() === 6) offset = 0; // sunday
    if (newDate.weekday() === 5) offset = 24; // saturday
    else if (newDate.weekday() < 2) offset = 0; // mon, tue
    newDate.add(offset + MAX_INSERTION_HOURS, 'hours');
    return newDate.format('YYYY-MM-DD');
}

export const MAX_INSERTION_HOURS = 72;
