import gql from 'graphql-tag';
import Address from './Address';

export default gql`
	fragment AuthorisedContact on AuthorisedContact {
		ID
		RelationshipToDeceased
		Title
		FirstName
		MiddleName
		Surname
		PrimaryNumber
		SecondaryNumber
		Email
		Notes
		PostalAddressSameAsResidentialAddress
		PostalAddress {
			...Address
		}
		ResidentialAddress {
			...Address
		}
	}
	${Address}
`;
